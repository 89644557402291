import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _31186d84 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _484494f6 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _a7596a2c = () => interopDefault(import('../pages/app/index.vue' /* webpackChunkName: "pages/app/index" */))
const _54bb21dd = () => interopDefault(import('../pages/app-form/index.vue' /* webpackChunkName: "pages/app-form/index" */))
const _417f6a94 = () => interopDefault(import('../pages/birthday-sms/index.vue' /* webpackChunkName: "pages/birthday-sms/index" */))
const _721f459f = () => interopDefault(import('../pages/birthdays/index.vue' /* webpackChunkName: "pages/birthdays/index" */))
const _2ecad196 = () => interopDefault(import('../pages/book/index.vue' /* webpackChunkName: "pages/book/index" */))
const _3957697e = () => interopDefault(import('../pages/careers/index.vue' /* webpackChunkName: "pages/careers/index" */))
const _046e1618 = () => interopDefault(import('../pages/contact-us/index.vue' /* webpackChunkName: "pages/contact-us/index" */))
const _5fa3502c = () => interopDefault(import('../pages/corporate-offers/index.vue' /* webpackChunkName: "pages/corporate-offers/index" */))
const _7bc067e1 = () => interopDefault(import('../pages/delivery/index.vue' /* webpackChunkName: "pages/delivery/index" */))
const _4c532916 = () => interopDefault(import('../pages/download/index.vue' /* webpackChunkName: "pages/download/index" */))
const _7ba35e63 = () => interopDefault(import('../pages/e-club-sign-up-lost-pins/index.vue' /* webpackChunkName: "pages/e-club-sign-up-lost-pins/index" */))
const _67b63138 = () => interopDefault(import('../pages/eclub/index.vue' /* webpackChunkName: "pages/eclub/index" */))
const _5413a7e8 = () => interopDefault(import('../pages/eclub-social/index.vue' /* webpackChunkName: "pages/eclub-social/index" */))
const _47ca0f46 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _736b4512 = () => interopDefault(import('../pages/experiences/index.vue' /* webpackChunkName: "pages/experiences/index" */))
const _70f8110c = () => interopDefault(import('../pages/family-zone-eclub/index.vue' /* webpackChunkName: "pages/family-zone-eclub/index" */))
const _85a23770 = () => interopDefault(import('../pages/food.vue' /* webpackChunkName: "pages/food" */))
const _487c5a44 = () => interopDefault(import('../pages/fun-cards/index.vue' /* webpackChunkName: "pages/fun-cards/index" */))
const _44545d1a = () => interopDefault(import('../pages/gift-cards/index.vue' /* webpackChunkName: "pages/gift-cards/index" */))
const _bcdb2fdc = () => interopDefault(import('../pages/investor-franchise.vue' /* webpackChunkName: "pages/investor-franchise" */))
const _39189756 = () => interopDefault(import('../pages/kidzbop.vue' /* webpackChunkName: "pages/kidzbop" */))
const _df1c6ff4 = () => interopDefault(import('../pages/leaguesleaderboard/index.vue' /* webpackChunkName: "pages/leaguesleaderboard/index" */))
const _16ddd532 = () => interopDefault(import('../pages/locations/index.vue' /* webpackChunkName: "pages/locations/index" */))
const _65407a9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _55c3d21b = () => interopDefault(import('../pages/luck.vue' /* webpackChunkName: "pages/luck" */))
const _5fdef23a = () => interopDefault(import('../pages/nco/index.vue' /* webpackChunkName: "pages/nco/index" */))
const _58c4c500 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _23678b78 = () => interopDefault(import('../pages/orlando-eclub/index.vue' /* webpackChunkName: "pages/orlando-eclub/index" */))
const _4fc50486 = () => interopDefault(import('../pages/our-story/index.vue' /* webpackChunkName: "pages/our-story/index" */))
const _3b6339bf = () => interopDefault(import('../pages/safety/index.vue' /* webpackChunkName: "pages/safety/index" */))
const _1fb125d6 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _3518d743 = () => interopDefault(import('../pages/site-map/index.vue' /* webpackChunkName: "pages/site-map/index" */))
const _53a23b47 = () => interopDefault(import('../pages/specials/index.vue' /* webpackChunkName: "pages/specials/index" */))
const _7aff18c8 = () => interopDefault(import('../pages/stories/index.vue' /* webpackChunkName: "pages/stories/index" */))
const _199014bf = () => interopDefault(import('../pages/trash-panda-eclub-signup/index.vue' /* webpackChunkName: "pages/trash-panda-eclub-signup/index" */))
const _94af7a60 = () => interopDefault(import('../pages/virtual-experiences-faqs/index.vue' /* webpackChunkName: "pages/virtual-experiences-faqs/index" */))
const _1051214b = () => interopDefault(import('../pages/virtualexperiences/index.vue' /* webpackChunkName: "pages/virtualexperiences/index" */))
const _1412ccb2 = () => interopDefault(import('../pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _03016a0a = () => interopDefault(import('../pages/birthday-sms/more-info.vue' /* webpackChunkName: "pages/birthday-sms/more-info" */))
const _28fd7cc0 = () => interopDefault(import('../pages/birthday-sms/thank-you.vue' /* webpackChunkName: "pages/birthday-sms/thank-you" */))
const _0588ddb0 = () => interopDefault(import('../pages/birthdays/packages.vue' /* webpackChunkName: "pages/birthdays/packages" */))
const _74ecdbab = () => interopDefault(import('../pages/book/appcheckout.vue' /* webpackChunkName: "pages/book/appcheckout" */))
const _eabc439c = () => interopDefault(import('../pages/book/checkout.vue' /* webpackChunkName: "pages/book/checkout" */))
const _aa7664f8 = () => interopDefault(import('../pages/book/confirm.vue' /* webpackChunkName: "pages/book/confirm" */))
const _8742d4f6 = () => interopDefault(import('../pages/book/events.vue' /* webpackChunkName: "pages/book/events" */))
const _61288d80 = () => interopDefault(import('../pages/book/order-confirmation.vue' /* webpackChunkName: "pages/book/order-confirmation" */))
const _69ae4192 = () => interopDefault(import('../pages/e-club-sign-up-lost-pins/more-info.vue' /* webpackChunkName: "pages/e-club-sign-up-lost-pins/more-info" */))
const _98ae9266 = () => interopDefault(import('../pages/e-club-sign-up-lost-pins/thank-you.vue' /* webpackChunkName: "pages/e-club-sign-up-lost-pins/thank-you" */))
const _02ca9f40 = () => interopDefault(import('../pages/eclub-social/more-info.vue' /* webpackChunkName: "pages/eclub-social/more-info" */))
const _31caf014 = () => interopDefault(import('../pages/eclub-social/thank-you.vue' /* webpackChunkName: "pages/eclub-social/thank-you" */))
const _7dcbffb8 = () => interopDefault(import('../pages/eclub/more-info.vue' /* webpackChunkName: "pages/eclub/more-info" */))
const _664bd74e = () => interopDefault(import('../pages/eclub/thank-you.vue' /* webpackChunkName: "pages/eclub/thank-you" */))
const _a4a572e0 = () => interopDefault(import('../pages/events/teambuilding/index.vue' /* webpackChunkName: "pages/events/teambuilding/index" */))
const _67bbd540 = () => interopDefault(import('../pages/family-zone-eclub/more-info.vue' /* webpackChunkName: "pages/family-zone-eclub/more-info" */))
const _96bc2614 = () => interopDefault(import('../pages/family-zone-eclub/thank-you.vue' /* webpackChunkName: "pages/family-zone-eclub/thank-you" */))
const _ee8a9c2e = () => interopDefault(import('../pages/kidzbopsweepstakes/enter.vue' /* webpackChunkName: "pages/kidzbopsweepstakes/enter" */))
const _74d37bcd = () => interopDefault(import('../pages/menu/bar.vue' /* webpackChunkName: "pages/menu/bar" */))
const _248fb54f = () => interopDefault(import('../pages/menu/events.vue' /* webpackChunkName: "pages/menu/events" */))
const _b11cb4d8 = () => interopDefault(import('../pages/menu/food.vue' /* webpackChunkName: "pages/menu/food" */))
const _5bcdc7b7 = () => interopDefault(import('../pages/nco/more-info.vue' /* webpackChunkName: "pages/nco/more-info" */))
const _444d9f4d = () => interopDefault(import('../pages/nco/thank-you.vue' /* webpackChunkName: "pages/nco/thank-you" */))
const _6c3679cc = () => interopDefault(import('../pages/orlando-eclub/more-info.vue' /* webpackChunkName: "pages/orlando-eclub/more-info" */))
const _54b65162 = () => interopDefault(import('../pages/orlando-eclub/thank-you.vue' /* webpackChunkName: "pages/orlando-eclub/thank-you" */))
const _3da18ec6 = () => interopDefault(import('../pages/password/forgot.vue' /* webpackChunkName: "pages/password/forgot" */))
const _7b0ae076 = () => interopDefault(import('../pages/password/reset.vue' /* webpackChunkName: "pages/password/reset" */))
const _7da9bb2e = () => interopDefault(import('../pages/summerseasonpass/registration/index.vue' /* webpackChunkName: "pages/summerseasonpass/registration/index" */))
const _f54a41ae = () => interopDefault(import('../pages/trash-panda-eclub-signup/thank-you.vue' /* webpackChunkName: "pages/trash-panda-eclub-signup/thank-you" */))
const _26eb7200 = () => interopDefault(import('../pages/virtualexperiences/book.vue' /* webpackChunkName: "pages/virtualexperiences/book" */))
const _51935284 = () => interopDefault(import('../pages/virtualexperiences/packages.vue' /* webpackChunkName: "pages/virtualexperiences/packages" */))
const _16b3cb44 = () => interopDefault(import('../pages/winter-season-pass/registration/index.vue' /* webpackChunkName: "pages/winter-season-pass/registration/index" */))
const _91e38bd6 = () => interopDefault(import('../pages/summerseasonpass/registration/_barcode.vue' /* webpackChunkName: "pages/summerseasonpass/registration/_barcode" */))
const _39d74ce5 = () => interopDefault(import('../pages/winter-season-pass/registration/_barcode.vue' /* webpackChunkName: "pages/winter-season-pass/registration/_barcode" */))
const _19664220 = () => interopDefault(import('../pages/book/_type/index.vue' /* webpackChunkName: "pages/book/_type/index" */))
const _37a0c836 = () => interopDefault(import('../pages/careers/_slug.vue' /* webpackChunkName: "pages/careers/_slug" */))
const _6ef861d8 = () => interopDefault(import('../pages/events/_category/index.vue' /* webpackChunkName: "pages/events/_category/index" */))
const _71b4a3ca = () => interopDefault(import('../pages/experiences/_slug.vue' /* webpackChunkName: "pages/experiences/_slug" */))
const _82ab32e0 = () => interopDefault(import('../pages/locations/_state/index.vue' /* webpackChunkName: "pages/locations/_state/index" */))
const _570e23b8 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _51eb99ff = () => interopDefault(import('../pages/specials/_slug.vue' /* webpackChunkName: "pages/specials/_slug" */))
const _7e6c5b58 = () => interopDefault(import('../pages/stories/_slug.vue' /* webpackChunkName: "pages/stories/_slug" */))
const _875aeafe = () => interopDefault(import('../pages/events/_category/play-academy.vue' /* webpackChunkName: "pages/events/_category/play-academy" */))
const _b7da770e = () => interopDefault(import('../pages/book/_type/_step.vue' /* webpackChunkName: "pages/book/_type/_step" */))
const _7265a468 = () => interopDefault(import('../pages/events/_category/_slug.vue' /* webpackChunkName: "pages/events/_category/_slug" */))
const _7bc0ccf8 = () => interopDefault(import('../pages/locations/_state/_id.vue' /* webpackChunkName: "pages/locations/_state/_id" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _08b4f1e0 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _31186d84,
    name: "404"
  }, {
    path: "/account",
    component: _484494f6,
    name: "account"
  }, {
    path: "/app",
    component: _a7596a2c,
    name: "app"
  }, {
    path: "/app-form",
    component: _54bb21dd,
    name: "app-form"
  }, {
    path: "/birthday-sms",
    component: _417f6a94,
    name: "birthday-sms"
  }, {
    path: "/birthdays",
    component: _721f459f,
    name: "birthdays"
  }, {
    path: "/book",
    component: _2ecad196,
    name: "book"
  }, {
    path: "/careers",
    component: _3957697e,
    name: "careers"
  }, {
    path: "/contact-us",
    component: _046e1618,
    name: "contact-us"
  }, {
    path: "/corporate-offers",
    component: _5fa3502c,
    name: "corporate-offers"
  }, {
    path: "/delivery",
    component: _7bc067e1,
    name: "delivery"
  }, {
    path: "/download",
    component: _4c532916,
    name: "download"
  }, {
    path: "/e-club-sign-up-lost-pins",
    component: _7ba35e63,
    name: "e-club-sign-up-lost-pins"
  }, {
    path: "/eclub",
    component: _67b63138,
    name: "eclub"
  }, {
    path: "/eclub-social",
    component: _5413a7e8,
    name: "eclub-social"
  }, {
    path: "/events",
    component: _47ca0f46,
    name: "events"
  }, {
    path: "/experiences",
    component: _736b4512,
    name: "experiences"
  }, {
    path: "/family-zone-eclub",
    component: _70f8110c,
    name: "family-zone-eclub"
  }, {
    path: "/food",
    component: _85a23770,
    name: "food"
  }, {
    path: "/fun-cards",
    component: _487c5a44,
    name: "fun-cards"
  }, {
    path: "/gift-cards",
    component: _44545d1a,
    name: "gift-cards"
  }, {
    path: "/investor-franchise",
    component: _bcdb2fdc,
    name: "investor-franchise"
  }, {
    path: "/kidzbop",
    component: _39189756,
    name: "kidzbop"
  }, {
    path: "/leaguesleaderboard",
    component: _df1c6ff4,
    name: "leaguesleaderboard"
  }, {
    path: "/locations",
    component: _16ddd532,
    name: "locations"
  }, {
    path: "/login",
    component: _65407a9c,
    name: "login"
  }, {
    path: "/luck",
    component: _55c3d21b,
    name: "luck"
  }, {
    path: "/nco",
    component: _5fdef23a,
    name: "nco"
  }, {
    path: "/news",
    component: _58c4c500,
    name: "news"
  }, {
    path: "/orlando-eclub",
    component: _23678b78,
    name: "orlando-eclub"
  }, {
    path: "/our-story",
    component: _4fc50486,
    name: "our-story"
  }, {
    path: "/safety",
    component: _3b6339bf,
    name: "safety"
  }, {
    path: "/search",
    component: _1fb125d6,
    name: "search"
  }, {
    path: "/site-map",
    component: _3518d743,
    name: "site-map"
  }, {
    path: "/specials",
    component: _53a23b47,
    name: "specials"
  }, {
    path: "/stories",
    component: _7aff18c8,
    name: "stories"
  }, {
    path: "/trash-panda-eclub-signup",
    component: _199014bf,
    name: "trash-panda-eclub-signup"
  }, {
    path: "/virtual-experiences-faqs",
    component: _94af7a60,
    name: "virtual-experiences-faqs"
  }, {
    path: "/virtualexperiences",
    component: _1051214b,
    name: "virtualexperiences"
  }, {
    path: "/account/password",
    component: _1412ccb2,
    name: "account-password"
  }, {
    path: "/birthday-sms/more-info",
    component: _03016a0a,
    name: "birthday-sms-more-info"
  }, {
    path: "/birthday-sms/thank-you",
    component: _28fd7cc0,
    name: "birthday-sms-thank-you"
  }, {
    path: "/birthdays/packages",
    component: _0588ddb0,
    name: "birthdays-packages"
  }, {
    path: "/book/appcheckout",
    component: _74ecdbab,
    name: "book-appcheckout"
  }, {
    path: "/book/checkout",
    component: _eabc439c,
    name: "book-checkout"
  }, {
    path: "/book/confirm",
    component: _aa7664f8,
    name: "book-confirm"
  }, {
    path: "/book/events",
    component: _8742d4f6,
    name: "book-events"
  }, {
    path: "/book/order-confirmation",
    component: _61288d80,
    name: "book-order-confirmation"
  }, {
    path: "/e-club-sign-up-lost-pins/more-info",
    component: _69ae4192,
    name: "e-club-sign-up-lost-pins-more-info"
  }, {
    path: "/e-club-sign-up-lost-pins/thank-you",
    component: _98ae9266,
    name: "e-club-sign-up-lost-pins-thank-you"
  }, {
    path: "/eclub-social/more-info",
    component: _02ca9f40,
    name: "eclub-social-more-info"
  }, {
    path: "/eclub-social/thank-you",
    component: _31caf014,
    name: "eclub-social-thank-you"
  }, {
    path: "/eclub/more-info",
    component: _7dcbffb8,
    name: "eclub-more-info"
  }, {
    path: "/eclub/thank-you",
    component: _664bd74e,
    name: "eclub-thank-you"
  }, {
    path: "/events/teambuilding",
    component: _a4a572e0,
    name: "events-teambuilding"
  }, {
    path: "/family-zone-eclub/more-info",
    component: _67bbd540,
    name: "family-zone-eclub-more-info"
  }, {
    path: "/family-zone-eclub/thank-you",
    component: _96bc2614,
    name: "family-zone-eclub-thank-you"
  }, {
    path: "/kidzbopsweepstakes/enter",
    component: _ee8a9c2e,
    name: "kidzbopsweepstakes-enter"
  }, {
    path: "/menu/bar",
    component: _74d37bcd,
    name: "menu-bar"
  }, {
    path: "/menu/events",
    component: _248fb54f,
    name: "menu-events"
  }, {
    path: "/menu/food",
    component: _b11cb4d8,
    name: "menu-food"
  }, {
    path: "/nco/more-info",
    component: _5bcdc7b7,
    name: "nco-more-info"
  }, {
    path: "/nco/thank-you",
    component: _444d9f4d,
    name: "nco-thank-you"
  }, {
    path: "/orlando-eclub/more-info",
    component: _6c3679cc,
    name: "orlando-eclub-more-info"
  }, {
    path: "/orlando-eclub/thank-you",
    component: _54b65162,
    name: "orlando-eclub-thank-you"
  }, {
    path: "/password/forgot",
    component: _3da18ec6,
    name: "password-forgot"
  }, {
    path: "/password/reset",
    component: _7b0ae076,
    name: "password-reset"
  }, {
    path: "/summerseasonpass/registration",
    component: _7da9bb2e,
    name: "summerseasonpass-registration"
  }, {
    path: "/trash-panda-eclub-signup/thank-you",
    component: _f54a41ae,
    name: "trash-panda-eclub-signup-thank-you"
  }, {
    path: "/virtualexperiences/book",
    component: _26eb7200,
    name: "virtualexperiences-book"
  }, {
    path: "/virtualexperiences/packages",
    component: _51935284,
    name: "virtualexperiences-packages"
  }, {
    path: "/winter-season-pass/registration",
    component: _16b3cb44,
    name: "winter-season-pass-registration"
  }, {
    path: "/summerseasonpass/registration/:barcode",
    component: _91e38bd6,
    name: "summerseasonpass-registration-barcode"
  }, {
    path: "/winter-season-pass/registration/:barcode?",
    component: _39d74ce5,
    name: "winter-season-pass-registration-barcode"
  }, {
    path: "/book/:type",
    component: _19664220,
    name: "book-type"
  }, {
    path: "/careers/:slug",
    component: _37a0c836,
    name: "careers-slug"
  }, {
    path: "/events/:category",
    component: _6ef861d8,
    name: "events-category"
  }, {
    path: "/experiences/:slug",
    component: _71b4a3ca,
    name: "experiences-slug"
  }, {
    path: "/locations/:state",
    component: _82ab32e0,
    name: "locations-state"
  }, {
    path: "/news/:slug",
    component: _570e23b8,
    name: "news-slug"
  }, {
    path: "/specials/:slug",
    component: _51eb99ff,
    name: "specials-slug"
  }, {
    path: "/stories/:slug",
    component: _7e6c5b58,
    name: "stories-slug"
  }, {
    path: "/events/:category/play-academy",
    component: _875aeafe,
    name: "events-category-play-academy"
  }, {
    path: "/book/:type/:step",
    component: _b7da770e,
    name: "book-type-step"
  }, {
    path: "/events/:category/:slug",
    component: _7265a468,
    name: "events-category-slug"
  }, {
    path: "/locations/:state/:id",
    component: _7bc0ccf8,
    name: "locations-state-id"
  }, {
    path: "/",
    component: _0547af50,
    name: "index"
  }, {
    path: "/:slug",
    component: _08b4f1e0,
    name: "slug"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
